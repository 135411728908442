
import { Section } from 'fsxa-api'
import { FSXARenderContentElement } from 'fsxa-pattern-library'
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'LeadGenerationContent',
  components: { FSXARenderContentElement },
})
export default class LeadGenerationContent extends Vue {
  @Prop() content! : Section[]
}
